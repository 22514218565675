/**
 * @file Viewport 最適化
 *
 */

var ua = navigator.userAgent.toLowerCase();
var windowSize = window.innerWidth;
var windowHeight = window.innerHeight;
var metalist = document.getElementsByTagName('meta');
var viewSize = 1230;
var displaySize = 375;
for (var i = 0; i < metalist.length; i++) {
  var name = metalist[i].getAttribute('name');
  if (name && name.toLowerCase() === 'viewport') {
    if (
      ua.indexOf('ipad') > 0 ||
      (ua.indexOf('macintosh') > -1 && 'ontouchend' in document) ||
      (ua.indexOf('android') > 0 && ua.indexOf('mobile') < 0)
    ) {
      metalist[i].setAttribute('content', 'width=' + viewSize + '');
    } else if (windowSize < displaySize || windowHeight < displaySize) {
      metalist[i].setAttribute('content', 'width=' + displaySize);
    }
  }
}
